<template>
  <div
    class="d-flex"
    :class="{ ['position-relative']: hover && ellipsis }"
    @mouseleave="hover = false"
    @mouseenter="hover = true"
  >
    <p ref="text" class="w-100 text-truncate d-inline-block text-nowrap px-1 mx-n1">
      <slot />
    </p>
    <p
      v-if="hover && ellipsis"
      class="d-inline-block px-1 mx-n1 bg-white shadow rounded position-absolute"
      :class="{ 'text-nowrap': textNoWrap }"
      style="z-index: 1"
    >
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    textNoWrap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hover: false,
      ellipsis: false,
    };
  },
  updated() {
    this.ellipsis = this.$refs.text.offsetWidth < this.$refs.text.scrollWidth;
  },
};
</script>
